import { Link } from 'gatsby'
import { Seo, ContactForm } from '~/components'
import Arrow from '~/images/common_icon_arrow_r.svg'

const ContactOther = () => (
  <>
    <Seo title="その他のお問い合わせ" pageUrl="contact-other" />
    <div className="pageComponent">
      <article
        className="contact"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="titleComponent contentComponent">
          <h1 className="heading pageTitle">CONTACT</h1>
          <p>その他のお問い合わせ</p>
        </header>
        <nav className="contentComponent">
          <ul className="breadcrumbComponent mincho">
            <li>
              <Link to="/" itemProp="url">
                TOP
              </Link>{' '}
              <span>
                <Arrow />
              </span>
            </li>
            <li>CONTACT</li>
          </ul>
        </nav>
        <div className="contentSubComponent">
          <ContactForm formId="06dc1e66-e785-4f6e-ac20-60df8f844490" />
        </div>
      </article>
    </div>
  </>
)

export default ContactOther
